import React from 'react';
import Layout from '../../components/layout/Layout';
import MainDescription from '../../components/MainDescription';
import PageNavigator from "../../components/layout/PageNavigator";

import desc from '../../data/corsi-patente-nautica/patente-nautica-no-limit/data.json';
import PageNavigatorWhite from '../../components/layout/PageNavigatorWhite';
import Seo from '../../components/layout/SEO';

const PatenteNautica12Miglia = () => {
  return (
    <Layout>
      <Seo
        title={"Patente Nautica No Limit | Autoscuola Faro, Pisa"}
        description={"Conseguila Patente Nautica oltre le 12 miglia dalla costa e senza un limite di potenza per il natante."}
        keywords={[
          "Patente Nautica Senza Limiti Pisa",
          "Patente Nautica Oltre 12 Miglia Pisa",
          "Conseguire Patente Nautica Pisa",
          "Patente Nautica Potenza Natante",
          "Autoscuola Faro Pisa"
        ]}
      />

      <PageNavigatorWhite
        link="/patenti-nautiche"
        text="Corsi patenti nautiche"
        bgColor="blue-accent"
        bgIntensity="400"
        textColor="gray"
        textIntensity="100"
      />
      <MainDescription title={desc.title} par1={desc.par1} par2={desc.par2} />
    </Layout>
  )
};

export default PatenteNautica12Miglia;
